import { createAction, props } from '@ngrx/store';
import { Tip } from '../../models';

export const LoadTips = createAction('[Tips] Load Tips');
export const LoadTipsSuccess = createAction('[Tips] Load Tips Success', props<{ tip: Tip[] }>());
export const LoadTipsFail = createAction('[Tips] Load Tips Fail', props<{ error: any }>());

export const LoadInsightsTips = createAction('[Tips] Load Insights Tips');
export const LoadInsightsTipsSuccess = createAction('[Tips] Load Insights Tips Success', props<{ tip: Tip[] }>());
export const LoadInsightsTipsFail = createAction('[Tips] Load Insights Tips Fail', props<{ error: any }>());

export const ClearTips = createAction('[Tips] Clear Tips');
