import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRoot from '../../../store/reducers';
import * as fromTips from '../reducers/tip.reducer';
import { Tip } from '../../models';
import * as e from 'express';

export const getTipState = createSelector(fromFeature.getLandingState, (state: fromFeature.TipState) => state.tip);

export const getTipEntities = createSelector(getTipState, fromTips.getTipsEntities);

export const getAllTips = createSelector(getTipEntities, entities => {
  return Object.keys(entities).map(id => entities[id as any]);
});

export const getOrderedTips = createSelector(getAllTips, tips => {
  return tips.sort((a, b) => new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime());
});

export const getRandomWastCalendarTip = createSelector(getAllTips, tips => {
  const filteredTips = tips.filter(tip => tip.showInCalendar);

  return filteredTips[Math.floor(Math.random() * filteredTips.length)];
});

export const getTipsLoaded = createSelector(getTipState, fromTips.getTipsLoaded);
export const getTipsLoading = createSelector(getTipState, fromTips.getTipsLoading);

export const getInsightsTipsLoaded = createSelector(getTipState, fromTips.getInsightsTipsLoaded);
export const getInsightsTipsLoading = createSelector(getTipState, fromTips.getInsightsTipsLoading);

export const getSelectedTip = createSelector(getTipEntities, fromRoot.getRouterState, (entities, router): Tip => {
  return router.state && entities[router.state.params?.['id'] || router.state.data?.['id']];
});

export const getRandomInsightsTips = createSelector(getAllTips, tips => {
  return tips.filter(tip => tip.category !== null && tip.category >= 0 && tip.showInInsights);
});
