import * as fromTips from './tip.reducer';

import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'tip';

export interface TipState {
  tip: fromTips.TipsState;
}

export const reducers: ActionReducerMap<TipState> = {
  tip: fromTips.TipsReducer,
};

export const getLandingState = createFeatureSelector<TipState>(featureKey);

export const metaReducers: MetaReducer<TipState>[] = !environment.production ? [] : [];
