import { createReducer, on } from '@ngrx/store';
import { Tip } from '../../models';
import * as pageActions from '../actions/tip.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface TipsState {
  entities: { [id: number]: Tip };
  loaded: boolean;
  loading: boolean;
  loadedInsights: boolean;
  loadingInsights: boolean;
  error?: any;
}

export const initialState: TipsState = {
  entities: {},
  loaded: false,
  loading: false,
  loadedInsights: false,
  loadingInsights: false,
};

export const TipsReducer = createReducer(
  initialState,
  on(pageActions.LoadTips, (state: TipsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadTipsSuccess, (state: TipsState, { tip }) => {
    const entities = ToEntities(tip, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
      loadedInsights: false,
    };
  }),
  on(pageActions.LoadTipsFail, (state: TipsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.LoadInsightsTips, (state: TipsState) => {
    return {
      ...state,
      loadingInsights: true,
    };
  }),
  on(pageActions.LoadInsightsTipsSuccess, (state: TipsState, { tip }) => {
    const entities = ToEntities(tip, 'id', {});
    return {
      ...state,
      entities,
      loadingInsights: false,
      loadedInsights: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadInsightsTipsFail, (state: TipsState, { error }) => {
    return {
      ...state,
      loadingInsights: false,
      loadedInsights: false,
      error,
    };
  }),
  on(pageActions.ClearTips, (state: TipsState) => {
    return {
      ...initialState,
    };
  })
);

export const getTipsEntities = (state: TipsState) => state.entities;

export const getTipsLoading = (state: TipsState) => state.loading;
export const getTipsLoaded = (state: TipsState) => state.loaded;

export const getInsightsTipsLoading = (state: TipsState) => state.loadingInsights;
export const getInsightsTipsLoaded = (state: TipsState) => state.loadedInsights;
