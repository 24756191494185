import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tip } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TipService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getTips() {
    return this.http.get<Tip[]>(`${this.BASE_URL}api/my-rova/tips`);
  }

  getInsightsTips() {
    return this.http.get<Tip[]>(`${this.BASE_URL}api/my-rova/tips/insights`);
  }
}
